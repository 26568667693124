<template>
  <div class="container" :style="'top: ' + top">
    <div class="form-container">
      <div class="title">找回密码</div>
      <div class="title sub">验证码将会发送至你的注册手机</div>
      <el-form
          :model="form"
          :rules="formRule"
          status-icon
          ref="formForgotPassword"
          class="demo-ruleForm"
      >
        <el-form-item prop="userPhone">
          <el-input prefix-icon="el-icon-phone" placeholder="请输入手机号"
                    v-model="form.userPhone"></el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-input prefix-icon="el-icon-chat-dot-square" type="text" placeholder="短信验证码"
                    v-model="form.smsCode">
            <template slot="append">
              <span class="sms" @click="send_sms">{{ sms_interval }}</span>
            </template>
          </el-input>
        </el-form-item>
        <div class="sub">输入新密码</div>
        <el-form-item prop="password" style="margin: 10px 0 20px;">
          <el-input prefix-icon="el-icon-view" type="password" placeholder="请输入新密码"
                    v-model="form.password"></el-input>
        </el-form-item>
        <!--<div class="sub tips">密码必须为8-20个英文字母、数字或符号 (除空格) ，且字母、数字和 标点符号至少包含两种。</div>-->
        <el-form-item>
          <el-button size="medium" type="primary" @click="clickResetPassword" style="width: 100%">重置密码</el-button>
        </el-form-item>
      </el-form>
    </div>
  
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "forgotPassword",
  
  data() {
    // 手机的校验方法
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const phoneNameRule = /^1[3-9][0-9]{9}$/;
      if (phoneNameRule.test(value)) {
        this.is_send = true;
        return callback();
      } else {
        return callback(new Error("请输入正确手机号"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      if (5 < value.length < 18) {
        // this.$refs.formForgotPassword.validateField("password");
        return callback();
      } else {
        return callback(new Error("请输入长度6-18之间密码"));
      }
    };
    
    return {
      form: {
        userPhone: "",
        smsCode: "",
        password: "",
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      formRule: {
        userPhone: [{ validator: validatePhone, trigger: "blur" }],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
      sms_interval: "获取验证码",
      is_send: false,
    };
  },
  computed: {
    top() {
      return this.$store.getters.getStickyHeight
    },
  },
  methods: {
    ...mapActions([]),
    clickResetPassword() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs.formForgotPassword.validate((valid) => {
        //如果通过校验开始登录
        if (valid) {
          this.$axios
              .post(`/app-api/hiring/auth/forget-password`, {
                params: {
                  mobile: this.form.userPhone,
                  code: this.form.smsCode,
                  password: this.form.password,
                }
              })
              .then((res) => {
                // “0”代表登录成功，其他的均为失败
                if (res.data.code === 0) {
                  // 弹出通知框提示登录成功信息
                  this.notifySucceed(res.data.message);
                  this.$router.push({ name: 'login' })
                } else {
                  // 清空输入框的校验状态
                  this.$refs.formForgotPassword.resetFields();
                  // 弹出通知框提示登录失败信息
                  this.notifyError(res.data.message);
                }
              })
              .catch((err) => {
                return Promise.reject(err);
              });
        } else {
          return false;
        }
      });
    },
    send_sms() {
      if (this.form.userPhone === "") {
        return this.$message.warning("请输入手机号");
      }
      if (!this.form.userPhone.match(/^1[3-9][0-9]{9}$/)) {
        return this.$message.warning("请输入正确手机号");
      }
      if (!this.is_send) return;
      this.is_send = false;
      let sms_interval_time = 60;
      this.sms_interval = "发送中...";
      this.$axios
          .post(
              `/app-api/hiring/auth/send-sms-code-forget-password`, { mobile: this.form.userPhone }
          )
          .then((res) => {
            if (res.data.code === 0) {
              this.$message({
                message: "短信发送成功",
                type: "success",
              });
              let timer = setInterval(() => {
                if (sms_interval_time <= 1) {
                  clearInterval(timer);
                  this.sms_interval = "获取验证码";
                  this.is_send = true; // 重新回复点击发送功能的条件
                } else {
                  sms_interval_time -= 1;
                  this.sms_interval = `${sms_interval_time}秒后再发`;
                }
              }, 1000);
            } else {
              this.$message({
                message: "短信发送失败，请稍后再试。。。",
                type: "error",
              });
              this.sms_interval = "获取验证码";
            }
          });
    },
  },
};
</script>

<style scoped lang="less">
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #F7FBFE;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 60px 0;
  
  .form-container {
    a {
      color: #409EFF;
    }
    
    .el-form-item {
      margin: 20px 0;
    }
    
    .title {
      font-size: 28px;
      font-weight: bold;
    }
    
    .sub {
      color: #999999;
      font-size: 14px;
      font-weight: unset;
      width: 300px;
      margin: 10px 0;
    }
    
    .tips {
      font-size: 12px;
    }
    
  }
}


.login-symbol {
  background: #888888;
  width: 1px;
  height: 16px;
  margin: 0 40px;
}

.login {
  position: relative;
  
  .loginTips {
    position: absolute;
    right: 0;
    top: -25px;
    color: #409EFF;
    cursor: pointer;
  }
}

.sms {
  color: orange;
  cursor: pointer;
  display: inline-block;
  width: 70px;
  text-align: center;
  user-select: none;
}

.el-checkbox > span {
  line-height: unset;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
</style>
